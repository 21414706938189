import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import swal from 'sweetalert'
import Modal from "react-modal"
import { Box, Grid, Badge, Button } from "theme-ui"
import { isEmpty } from "lodash"
import '../styles/blog-post.css'; // Import regular stylesheet


function BlogPost({ data }) {
  const post = data.allWordpressPost.edges[0].node

  const [formState, setFormState] = useState({ name:"", email: "", 
  title: post.title, content: post.content, image: post.featured_media.localFile.url});
  //pass the name of article to the users
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  /** 
   * fixing bugs: submit form: 404 err 
   * pass value of the page, and data (post wordpress id)
   * how should it will be automatically send the essays back : send 5 each automatically and delete, based on the wp id 
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (formState.name == "" || formState.email == "" || formState.title == "" 
        || formState.name == null || formState.email == null || formState == null ){
          swal("Cancelled", "Please fill in the form", "error")
        }else{
        fetch("/", {
          method: "POST",
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: encode({ "form-name": "blogletter", ...formState})
          }).then(() => swal("Good job!", "Thanks for being interested in this essay! In a short time, you will receive this essay in pdf in your email box.", "success")).catch(error => alert(error))
    }
  }

  return (
    <Layout>
      <div>
        <h1 dangerouslySetInnerHTML={{__html: post.title}} />
        <Badge>{post.categories[0].name}</Badge>
        <Grid gap={2} columns={[2, '1fr 2fr']} className="blogs-display">
            <Box className="blogs-img">
              <Img  key={post.featured_media.localFile.childImageSharp.resolutions.src} fluid={post.featured_media.localFile.childImageSharp.fluid} />
              <br/>
              </Box>
              <Box>
                <div dangerouslySetInnerHTML={{__html: post.content}} />
                <Button style={{width: "100%"}}  onClick={toggleModal}>Email a free copy to me</Button>

              </Box>
        </Grid>
                <form  name="blogletter" data-netlify="true" hidden>
                <input type="text" name="name" />
                <input type="email" name="email" />
                <input type="text" name="title" value={post.title} />
                <input type="text" name="content" value={post.content} />
                <input type="image" name="image" src={post.featured_media.localFile.url} />
                </form>
                <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModal}
                  className="modal">
                  <div class="modal-body">
                  <form  method="post" name="blogletter" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit} 
                  className="forms-display">
                  <div hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                    </div>
                  <input type="hidden" name="form-name" value="blogletter" />
                  <h4>Fill in your email so I will send the article to you</h4>
                  <p>
                  <label>Title&nbsp;&nbsp;&nbsp;</label>
                  <input className="forms-input" type="text" name="title" value={post.title} readonly/>
                  </p>
                  <p>
                  <label>Name </label>
                  <input className="forms-input" type="text" name="name" value={formState.name} onChange={handleChange}/>
                  </p>
                  <p>
                  <label>Email </label>
                  <input className="forms-input" type="email" name="email" value={formState.email} onChange={handleChange}/>
                  </p>
                  <p>
                  <Button type="submit">Send</Button>
                  </p>
                  <input type="hidden" name="content" value={formState.content} onChange={handleChange}/>
                  <input type="hidden" name="image"   value={formState.image} onChange={handleChange}/>
                  </form>
                  </div>
                  <div class="modal-footer">
                  <Button style={{right:"10%",  top:"30%", position: "absolute"}} onClick={toggleModal}>X</Button>
                  </div>
                </Modal>
            </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    allWordpressPost(filter: { slug: { eq: $slug } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          content
          date(formatString: "MMMM DD, YYYY")
          categories {
            id
            name
          }
          featured_media {
            localFile {
                url
                childImageSharp {
                  fluid(maxWidth:350, quality:100){
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                  resolutions(width:350, height: 500) {
                    ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                  }
                }
          }
        }
          
        }
      }
    }
  }
`